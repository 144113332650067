import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledDiv = styled.div`
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 37.5em) {
    padding: 0 35px;
  }

  ${({ $wide }) =>
    !$wide &&
    css`
      @media (min-width: 64em) {
        width: 85%;
        max-width: 1400px;
      }
    `}

  @media (min-width: 90.063em) {
    width: auto;
  }
`;

const Container = ({ className, wide, children }) => (
  <StyledDiv className={className} $wide={wide}>
    {children}
  </StyledDiv>
);

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Container;
