import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';

//Component Imports
import Button from '../buttons';

//Styled Components
const DropdownContainer = styled.div`
  --siteMenuArrowSize: 16px;
  --siteMenuArrowSpacing: 13px;
  --siteMenuArrowXOffset: -20px;

  --dropdownBaseWidth: 500;
  --dropdownBaseHeight: 250;

  --dropdownWidth: 604;
  --dropdownHeight: 152;
  --dropdownXOffset: 0;
  --dropdownPadding: 32;

  position: absolute;
  left: 0;
  right: 0;
  perspective: 2000px;

  pointer-events: ${({ $isDropdownOpen }) => ($isDropdownOpen ? '' : 'none')};
  opacity: ${({ $isDropdownOpen }) => ($isDropdownOpen ? 1 : 0)};
  transform: ${({ $isDropdownOpen }) =>
    $isDropdownOpen ? 'rotateX(0)' : 'rotateX(-15deg)'};

  transform-origin: left top;
  transition:
    transform 0.3s,
    opacity 0.3s;

  display: inline-block;

  h1 {
    font-size: var(--font-size-button);
    font-family: Poppins;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: var(--blue3);
  }
`;

const DropdownArrow = styled.div`
  position: absolute;
  top: 8px;
  left: 50%;
  width: var(--siteMenuArrowSize);
  height: var(--siteMenuArrowSize);
  margin-left: calc(-1 * (var(--siteMenuArrowSize) / 2));
  border-radius: 3px;
  background-color: var(--blue6);
  box-shadow: -3px -3px 5px rgb(82 95 127 / 4%);
  z-index: 2;

  transform: translateY(0) translateX(var(--siteMenuArrowXOffset)) rotate(45deg);

  transition-property: transform;
  transition-duration: 0.3s;
`;

const DropdownBackground = styled.div`
  position: absolute;
  top: var(--siteMenuArrowSpacing);
  left: 0;

  width: calc(var(--dropdownBaseWidth) * 1px);
  height: calc(var(--dropdownBaseHeight) * 1px);

  padding: calc(var(--dropdownPadding) * 1px);
  min-height: 72px;
  z-index: 1;

  background-color: var(--blue6);
  border-radius: 5px;
  box-shadow:
    0 50px 100px -20px rgba(50, 50, 93, 0.5),
    0 30px 60px -30px rgba(0, 0, 0, 0.3);

  transition: transform 250ms;
  transform-origin: 0 0;

  // transform: scale( ((newX)/(baseX)) , ((newY)/(baseY)))
  transform: translateX(var(--dropdownXOffset))
    scaleX(
      calc(
        (var(--dropdownWidth) + (var(--dropdownPadding) * 2)) /
          var(--dropdownBaseWidth)
      )
    )
    scaleY(
      calc(
        (var(--dropdownHeight) + (var(--dropdownPadding) * 2)) /
          var(--dropdownBaseHeight)
      )
    );
`;

const DropdownContent = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;

  z-index: 2;
  padding: calc(var(--dropdownPadding) * 1px);
  padding-top: calc(
    calc(var(--dropdownPadding) * 1px) + var(--siteMenuArrowSpacing)
  );

  width: calc((var(--dropdownWidth) + (var(--dropdownPadding) * 2)) * 1px);
  height: calc((var(--dropdownHeight) + (var(--dropdownPadding) * 2)) * 1px);

  transition: transform 250ms;
  transform: translateX(var(--dropdownXOffset));
`;

const DropdownSection = styled.section`
  position: absolute;
  overflow: hidden;
  opacity: ${({ $activeMenu, name }) => ($activeMenu === name ? 1 : 0)};
  pointer-events: ${({ $activeMenu, name }) =>
    $activeMenu === name ? '' : 'none'};
  transition: opacity 0.2s;
`;

const ProductsSection = styled(DropdownSection)``;

const SolutionsSection = styled(DropdownSection)``;

const ProductsSectionLayout = styled.ul`
  display: grid;
  grid: auto/repeat(2, 254px);
  max-width: 100%;
  gap: 24px 32px;

  --siteNavIconSize: 24px;
  --siteNavIconSpacingRight: 16px;
`;

const SolutionsSectionLayout = styled.ul`
  display: grid;
  grid: auto/repeat(2, 200px);

  gap: 24px 32px;

  --siteNavIconSize: 16px;
  --siteNavIconSpacingRight: 8px;
`;

const ProductsNavTitle = styled.h1`
  grid-column: span 2;
`;

const SolutionsNavTitle = styled.h1`
  grid-column: span 2;
`;

const SiteNavItem = styled.li`
  display: flex;
`;

const SiteNavItemIconContainer = styled.span`
  width: var(--siteNavIconSize);
  height: var(--siteNavIconSize);
  margin-right: var(--siteNavIconSpacingRight);
  flex: 0 0 auto;
`;

const SiteNavItemIcon = styled.svg`
  stroke: var(--blue4);
  stroke-width: 2px;
  stroke-linecap: round;
  fill: none;
  width: 100%;
  height: 100%;
  transition: stroke 0.2s;
`;

const SiteNavItemIconSecondary = styled(SiteNavItemIcon)`
  fill: var(--blue4);
  stroke: none;
  transition: fill 0.2s;
  > svg {
    fill: var(--blue4);
  }
`;

const SiteNavItemLink = styled(Link)`
  display: inline-flex;
  transition: opacity 0.1s ease;

  &:hover {
    .HoverArrow__linePath {
      opacity: 1;
    }

    .HoverArrow__tipPath {
      transform: translateX(3px);
    }

    ${SiteNavItemIcon} {
      stroke: var(--accent);
    }

    ${SiteNavItemIconSecondary} {
      fill: var(--accent);
      stroke: none;
      > svg {
        fill: var(--accent);
      }
    }
  }
`;

const SiteNavItemLabelContainer = styled.span`
  display: flex;
  flex-direction: column;
`;

const SiteNavItemLabel = styled(Button)`
  color: var(--blue1);
  padding: 0;
  line-height: 13px;
  margin-top: 3px; //Font error correction
  text-transform: none;
  text-align: left;
`;

const SiteNavItemSubtitle = styled.span`
  font-family: Proxima Nova;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-technical-small);
  line-height: 20px;
  letter-spacing: 0.03em;
  margin-top: 4px;
  max-width: 85%;
`;

const dimensions = {
  Products: {
    width: 0,
    height: 0,
    x: 0,
    arrowXOffset: 0,
  },
  Solutions: {
    width: 0,
    height: 0,
    x: 0,
    arrowXOffset: 0,
  },
};

const customSort = (arr, desiredOrder) => {
  const orderForIndexVals = desiredOrder.slice(0).reverse();
  return arr.sort((a, b) => {
    const aIndex = -orderForIndexVals.indexOf(a.productTag.slug.current);
    const bIndex = -orderForIndexVals.indexOf(b.productTag.slug.current);
    return aIndex - bIndex;
  });
};

const SiteHeaderDropdownMenu = ({
  isDropdownOpen,
  setDropdownOpen,
  activeMenu,
  productRoutes,
  solutionRoutes,
}) => {
  const dropdownContainer = useRef();
  const productsDropdownLayout = useRef();
  const solutionsDropdownLayout = useRef();

  //* On site load -> calculate dropdown dimensions for every section*//
  useEffect(() => {
    // Products dimensions
    dimensions.Products.width = productsDropdownLayout.current.offsetWidth;
    dimensions.Products.height = productsDropdownLayout.current.offsetHeight;
    dimensions.Products.x = -35;
    dimensions.Products.arrowXOffset = -150;
    // dimensions.Products.arrowXOffset = -85;

    // Solutions dimensions
    dimensions.Solutions.width = solutionsDropdownLayout.current.offsetWidth;
    dimensions.Solutions.height = solutionsDropdownLayout.current.offsetHeight;
    dimensions.Solutions.x = 115;
    dimensions.Solutions.arrowXOffset = -20;
  }, []);

  //* When activeMenu is changed, update dropdown sizing & positioning CSS variables
  useEffect(() => {
    dropdownContainer.current.style = `--dropdownHeight: ${dimensions[activeMenu].height}; --dropdownWidth: ${dimensions[activeMenu].width};
    --dropdownXOffset: ${dimensions[activeMenu].x}px;
    --siteMenuArrowXOffset: ${dimensions[activeMenu].arrowXOffset}px`;
  }, [activeMenu]);

  return (
    <DropdownContainer $isDropdownOpen={isDropdownOpen} ref={dropdownContainer}>
      <DropdownContent>
        <ProductsSection name={'Products'} $activeMenu={activeMenu}>
          <ProductsSectionLayout ref={productsDropdownLayout}>
            <ProductsNavTitle>Products</ProductsNavTitle>
            {customSort(productRoutes.nodes, [
              'tv-commercial',
              'corporate',
            ]).map(({ productTag }) => (
              <SiteNavItem key={productTag.slug.current}>
                <SiteNavItemLink
                  to={`/products/${productTag.slug.current}`}
                  onClick={() => setDropdownOpen(false)}
                >
                  <SiteNavItemIconContainer>
                    <SiteNavItemIcon>
                      <use
                        href={`/icons/feather-sprite.svg#${productTag.icon.name}`}
                      />
                    </SiteNavItemIcon>
                  </SiteNavItemIconContainer>
                  <SiteNavItemLabelContainer>
                    <SiteNavItemLabel
                      type={'link'}
                      label={productTag.label}
                      arrow
                    />
                    <SiteNavItemSubtitle>
                      {productTag.tagline}
                    </SiteNavItemSubtitle>
                  </SiteNavItemLabelContainer>
                </SiteNavItemLink>
              </SiteNavItem>
            ))}
          </ProductsSectionLayout>
        </ProductsSection>
        <SolutionsSection name={'Solutions'} $activeMenu={activeMenu}>
          <SolutionsSectionLayout ref={solutionsDropdownLayout}>
            <SolutionsNavTitle>Solutions By Industry</SolutionsNavTitle>
            {solutionRoutes.nodes.map(({ industryTag }) => (
              <SiteNavItem key={industryTag.slug.current}>
                <SiteNavItemLink
                  to={`/industry/${industryTag.slug.current}`}
                  onClick={() => setDropdownOpen(false)}
                >
                  <SiteNavItemIconContainer>
                    <SiteNavItemIconSecondary
                      dangerouslySetInnerHTML={{ __html: industryTag.svgLogo }}
                    />
                  </SiteNavItemIconContainer>
                  <SiteNavItemLabelContainer>
                    <SiteNavItemLabel
                      type={'link'}
                      label={industryTag.label}
                      arrow
                    />
                  </SiteNavItemLabelContainer>
                </SiteNavItemLink>
              </SiteNavItem>
            ))}
          </SolutionsSectionLayout>
        </SolutionsSection>
      </DropdownContent>
      <DropdownBackground />
      <DropdownArrow />
    </DropdownContainer>
  );
};

export default SiteHeaderDropdownMenu;

// Credit: Menu styling/implementation inspired by stripe.com
