import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

//Component Imports
import { ButtonText } from '../theme/typography';

const HoverArrow = styled.svg`
  --arrowSpacing: 5px;
  --arrowHoverOffset: translateX(3px);
  position: relative;
  top: 1px;
  margin-left: var(--arrowSpacing);
  stroke-width: 2px;
  fill: none;
  stroke: currentColor;
  vertical-align: baseline;
  margin-bottom: 1px;
`;

const ArrowLinePath = styled.path`
  opacity: 0;
  transition: ${({ theme }) => theme.transition.hover};
`;

const ArrowTipPath = styled.path`
  transition: ${({ theme }) => theme.transition.hover};
`;

const handleButtonType = (type, theme) => {
  switch (type) {
    case 'primary':
      return `color: ${theme.color.blue1}; background: ${theme.color.accent};`;
    case 'secondary':
      return `color: ${theme.color.white}; background: ${theme.color.blue1};`;
    case 'tertiary':
      return `color: ${theme.color.blue1}; background: ${theme.color.blue6};`;
    case 'light':
      return `color: ${theme.color.white}; background: hsla(0,0%,100%,0.2); &:hover { background: hsla(0,0%,100%,0.4); }`;
    case 'link':
      return `color: var(--primary-text, white); background: none; padding-left: 0; padding-right: 0;`;
    case 'linkAccent':
      return `color: ${theme.color.accent}; background: none; padding-left: 0; padding-right: 0;`;
    default:
      return `color: ${theme.color.blue1}; background: ${theme.color.accent};`;
  }
};

// TODO: Font styling is hard coded until new design system type is implemented
const CTAButton = styled(ButtonText)`
  padding-left: 16px;
  padding-right: ${({ $arrow }) => ($arrow ? '12px' : '16px')};
  white-space: nowrap;
  transition: ${({ theme }) => theme.transition.hover};
  transition-property: background-color;

  display: inline-block;
  padding-top: 5px;
  padding-bottom: 3px;
  border-radius: 16.5px;
  cursor: pointer;

  &:hover {
    opacity: ${({ type }) => (type === 'light' ? 1 : 0.9)};

    ${ArrowLinePath} {
      opacity: 1;
    }

    ${ArrowTipPath} {
      transform: var(--arrowHoverOffset);
    }
  }

  ${({ type, theme }) => handleButtonType(type, theme)};
`;

const CTAButtonLabel = styled.span``;

const Button = ({
  label,
  path,
  arrow = false,
  type = 'primary',
  state,
  ...props
}) => {
  return (
    <CTAButton
      $arrow={arrow ? 1 : 0}
      type={type}
      as={path ? Link : 'button'}
      to={path ? path : ''}
      state={state}
      {...props}
    >
      <CTAButtonLabel>{label}</CTAButtonLabel>
      {arrow && (
        <>
          &nbsp;
          <HoverArrow
            width='10'
            height='10'
            viewBox='0 0 10 10'
            aria-hidden='true'
          >
            <g fillRule='evenodd'>
              <ArrowLinePath
                className='HoverArrow__linePath'
                d='M0 5h7'
              ></ArrowLinePath>
              <ArrowTipPath
                className='HoverArrow__tipPath'
                d='M1 1l4 4-4 4'
              ></ArrowTipPath>
            </g>
          </HoverArrow>
        </>
      )}
    </CTAButton>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  arrow: PropTypes.bool,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'light',
    'link',
    'linkAccent',
    'submit',
  ]),
};

export default Button;
