import React, { useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

// Asset Imports
import Logo from '../../images/rubberduck-logotype.inline.svg';

// Component Imports
import Container from '../container';
import SiteHeaderNav from './siteHeaderNav';
import SiteHeaderMobileNav from './siteHeaderMobileNav';
import Button from '../buttons';

const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: var(--nav-background);
  --siteMenuArrowSpacing: 13px;
  --siteMenuTransition: 250ms;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  min-height: var(--site-header-height);
`;

const SiteHeaderLogo = styled.h1`
  transition: ${({ theme }) => theme.transition.hover};
  transition-property: opacity;

  &:hover {
    opacity: 0.6;
  }
`;

const SiteHeaderButton = styled(Button)`
  background: var(--nav-button-bg);
  color: var(--nav-color);

  display: none;
  @media (min-width: 64em) {
    display: block;
  }
`;

const MobileMenuButton = styled.svg`
  stroke: var(--nav-color);
  stroke-width: 2px;
  stroke-linecap: round;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: stroke 0.2s;

  &:hover {
    stroke: var(--blue3);
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

const NavRoutes = [
  {
    id: 0,
    title: 'Home',
    path: '/',
    subMenu: false,
  },
  {
    id: 1,
    title: 'Products',
    path: '/products',
    subMenu: true,
  },
  {
    id: 2,
    title: 'Solutions',
    path: '/solutions',
    subMenu: true,
  },
  {
    id: 3,
    title: 'Directors',
    path: '/directors',
    subMenu: false,
  },
  {
    id: 4,
    title: 'Portfolio',
    path: '/portfolio',
    subMenu: false,
  },
];

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allSanityProductPage {
        nodes {
          productTag {
            label
            tagline
            icon {
              name
            }
            slug {
              current
            }
          }
        }
      }
      allSanityIndustryPage {
        nodes {
          industryTag {
            label
            svgLogo
            slug {
              current
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Helmet></Helmet>
      <StyledHeader>
        <Container>
          <NavContainer>
            <SiteHeaderLogo>
              <a href='/'>
                <Logo style={{ fill: `var(--nav-color)` }} />
              </a>
            </SiteHeaderLogo>
            <SiteHeaderNav
              routes={NavRoutes}
              productRoutes={data.allSanityProductPage}
              solutionRoutes={data.allSanityIndustryPage}
            />
            <SiteHeaderButton label='Contact Us' path='/contact' arrow />
            <MobileMenuButton onClick={() => setIsMobileMenuOpen(true)}>
              <use href={`/icons/feather-sprite.svg#menu`} />
            </MobileMenuButton>
            <SiteHeaderMobileNav
              isMobileMenuOpen={isMobileMenuOpen}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              routes={NavRoutes}
              productRoutes={data.allSanityProductPage}
              solutionRoutes={data.allSanityIndustryPage}
            />
          </NavContainer>
        </Container>
      </StyledHeader>
    </>
  );
};

export default Header;
